class ZIDXAccountEmbeddableSearchEdit implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxAccountEmbeddableSearchEditContainer";
    }

    getMatchURL() {
        return "/account/embeddable-searches/edit";
    }

    render() {
        window.zidxBase.init(false, true, false, false);
        ZIDX.$("#embedSearchSave").on("click", (e: ClickEvent) => {
            e.preventDefault();
            window.zidxBase.checkForSearchFormChanges(false, true);
            let data = {
                "saved_search_embed_criteria": window.zidxBase.qs,
                "saved_search_embed_name": ZIDX.$("input[name=\"search_embed_name\"]").val(),
                "saved_search_embed_id": ZIDX.$("input[name=\"search_embed_id\"]").val()
            };
            if(data.saved_search_embed_name === ""){
                alert("Please enter a name for the search");
                return;
            }

            let obj = {
                id: "ajaxSaveSearchEmbed",
                method: "post",
                postObj: data,
                ignoreOldRequests: false,
                callback: function (r1: string) {
                    let r = JSON.parse(r1);
                    if (r.success) {
                        ZIDX.redirectFormResponse(r, "")
                    } else {
                        alert(r.errorMessage);
                    }
                },
                errorCallback: function (r: string) {
                    alert(r);
                },
                url: ZIDX.getPrefixURL("/account/embeddable-searches/save")
            };
            ZIDX.ajaxRequest(obj);
        });


    }


}